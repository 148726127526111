












































import ResolveFromCondition from './/ResolveFromCondition'
import { InputSetups } from '@/mixins/input-setups'
import SelectOptionsMixin from '@/mixins/SelectOptionsMixin'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'
import ResolveFromRankCondition from '../ResolveFromRankCondition/ResolveFromRankCondition'

@Component({
  data() {
    return {
      ResolveFromRankCondition,
    }
  }
})
export default class ResolveFromConditionView extends Mixins(UseFields, InputSetups, SelectOptionsMixin) {
  @VModel() model!: ResolveFromCondition

  @Prop() prefix!: string
}
